<template>
  <div class="detail">
    <div class="title">
      <img src="../../public/images/asset/my-income/back.png" alt="" @click="$router.go(-1)">
      {{ $t('myincome.desc57') }}
    </div>
    <div class="wrap info-list">
      <div class="info-item">
        <div class="til">{{ $t('myincome.desc58') }}</div>
        <div class="text">{{ info.earningDate }}</div>
      </div>
      <div class="info-item">
        <div class="til">{{ $t('myincome.desc62') }}</div>
        <div class="text">{{ common.cutXiaoNum1(info.investTotalAmount) }}{{ info.investCoinName }}</div>
      </div>
      <div class="info-item">
        <div class="til">{{ $t('myincome.desc59') }}</div>
        <div class="text on">{{ common.cutXiaoNum1(info.earningTotalAmount) }}{{ info.earningCoinName }}</div>
      </div>
    </div> 
    <div class="wrap" v-for="item in info.voList" :key="item.id">
      <div class="item">
        <div class="til">{{ $t('myincome.desc14') }}</div>
        <div class="text">{{ dealiIncomeType($route.query.incomeType) }}</div>
      </div>
      <div class="item">
        <div class="til">{{ $t('myincome.desc19') }}</div>
        <div class="text">
         <p>{{ item.earningAmount }}{{ item.earningCoinName }}</p>
          <P class="speed flex" v-if="item.activityAmount != 0  && $route.query.incomeType==6 || item.activityAmount != 0  && $route.query.incomeType==1">
                  <img src="../../public/images/new/speedicon.png" alt="" />
                  +{{ common.cutXiaoNum1(item.activityAmount) || 0 }} ({{ $t('market.desc206') }})
                </P>
        </div>
      </div>
      <div class="item">
        <div class="til">{{ $t('myincome.desc47') }}</div>
        <div class="text">{{ item.planetName }}</div>
      </div>
      <div class="item">
        <div class="til">{{ $t('myincome.desc48') }}</div>
        <div class="text">{{ item.coord }}</div>
      </div>
      <div class="item">
        <div class="til">{{ $t('myincome.desc49') }}</div>
        <div class="text">LV{{ item.landLevel }}{{ item.landLevelName }}</div>
      </div>
      <div class="item" v-if="$route.query.incomeType == 3 || $route.query.incomeType == 40">
        <div class="til">{{ $t('market.desc214') }}</div>
        <div class="text">{{ common.cutXiaoNum1(item.activityAmount) }}{{ item.investCoinName }}</div>
      </div>
      <div class="item" v-else>
        <div class="til">{{ $t('myincome.desc50') }}</div>
        <div class="text">{{ common.cutXiaoNum1(item.investTotalAmount) }}{{ item.investCoinName }}</div>
      </div>
      <div class="item" v-if="$route.query.incomeType == 3 || $route.query.incomeType == 40">
        <div class="til">{{ $t('market.desc215') }}</div>
        <div class="text">{{ common.cutXiaoNum1(item.activityRate * 100) || 0 }}%</div>
      </div>
      <div class="item" v-else>
        <div class="til">{{ $t('myincome.desc51') }}</div>
        <div class="text">
          {{ common.getnum(item.earningRate * 100) + '%' }}
          <P class="speed flex" v-if="item.activityRate != 0 && $route.query.incomeType==6 || item.activityRate != 0 && $route.query.incomeType==1">
                  <img src="../../public/images/new/speedicon.png" alt="" />
                  +{{ common.getnum(item.activityRate * 100) || 0 }}% ({{ $t('market.desc212') }})
                </P>
        </div>
      </div>
      <div class="item">
        <div class="til">{{ $t('myincome.desc52') }}</div>
        <div class="text">{{ dealiIncomeStatus(item.status) }}</div>
      </div>
    </div>
    <div class="no-data" v-if="showNull">
      <img src="../../public/images/no-data.png" alt="">
      <span>{{ $t('record.desc13') }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from "@/store";
export default {
  computed: {
    ...mapState(['assetType']),
  },
  data() {
    return {
      info: {},
      showNull: false
    }
  },
  mounted() {
    this.handlerInfo()
  },
  methods: {
    dealiIncomeType(type) { // 1-土地收益 2-土地税收  3-星球推荐奖 4-代数奖励
      if (type == 1 || type == 6) {
        return this.$t('myincome.desc2')
      } else if (type == 2) {
        return this.$t('myincome.desc30')
      } else if (type == 3) {
        return this.$t('myincome.desc31')
      } else {
        return this.$t('myincome.desc32')
      }
    },
    dealiIncomeStatus(type) { // 状态： 2- 待领取 3-已领取 5-已损失
      if (type == 2) {
        return this.$t('myincome.desc35')
      } else if (type == 3) {
        return this.$t('myincome.desc36')
      } else {
        return this.$t('myincome.desc37')
      }
    },
    handlerInfo() {
      let url = this.URL.myincome.detail
      if (this.$route.query.incomeType == 4) url = this.URL.myincome.teamEarningDetail
      this.$post(url, {
        sn: this.$route.query.sn
      }).then(res => {
        if (res.code == 0) {
          this.info = res.data;
          if (this.info.voList.length == 0) {
            this.showNull = true
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.detail {
  padding: 0 16px 16px;
  .title {
    position: relative;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 12px;
    img {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 14px;
      height: 22px;
    }
  }
  .wrap {
    margin-bottom: 14px;
    background: #181818;
    border-radius: 8px;
    padding: 16px;
    &:last-child {margin-bottom: 0;}
    &.info-list {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
    .info-item {
      width: calc(50% - 8px);
      .til {
        font-size: 12px;
        font-weight: 400;
        color: #A8A8A8;
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        &.on {color: #C9FA5B;}
      }
    }
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .til {
        font-size: 13px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
      }
      .text {
        font-size: 13px;
        font-weight: 500;
        color: #fff;
        text-align: right;
      }
    }
    .tips {
      padding-top: 16px;
      margin-top: 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.05);
      font-size: 12px;
      font-weight: 400;
      color: #E15757;
    }
  }
}
.speed {
    width: max-content;
    margin-top: 12px;
    border-radius: 6px;
    background: rgba(201, 250, 91, 0.10);
    font-size: 13px;
    color: #C9FA5B;
    padding: 4px;
    line-height: 14px;

    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
</style>
